/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

require('../css/app.css');
require('../css/contact.css');

require('@fortawesome/fontawesome-free/css/all.min.css');
const $ = require('jquery');
